export const DB_ES = 
{
    "consultingSteps": [
        {
            "id": 1,
            "name": "Modelling & Planning",
            "image": "consultant/planning.png",
            "description": "understanding the problem to solve",
            "inputs": "asdasd",
            "outputs": "",
            "methods": ""
        },
        {
            "id": 2,
            "name": "Design",
            "image": "consultant/desing.png",
            "description": "design of solution's alternatives",
            "inputs": "asdasd",
            "outputs": "",
            "methods": ""
        },
        {
            "id": 3,
            "name": "Implementation",
            "image": "consultant/implementation.png",
            "description": "prototyping the selected solutions (workload)",
            "inputs": "asdasd",
            "outputs": "",
            "methods": ""
        },
        {
            "id": 4,
            "name": "Tracing & Testing",
            "image": "consultant/tracing.png",
            "description": "prototyping the selected solutions (workload)",
            "inputs": "asdasd",
            "outputs": "",
            "methods": ""
        }
    ],
    "services": [
        {
            "id": 1,
            "name": "Embedded development",
            "image": "services/embedded-development.png",
            "noHiredProjects": 0,
            "description": "We wor custom product prototypes "
        },
        {
            "id": 2,
            "name": "Web/App Development",
            "image": "services/web-app-development.png",
            "noHiredProjects": 0,   
            "description": ""
        },
        {
            "id": 3,
            "name": "Cloud Automation", 
            "image": "services/cloud-automation.png",
            "noHiredProjects": 0,
            "description": ""
        },
        {
            "id": 4,
            "name": "Analytics & AI",
            "image": "services/analytics-and-ai.png",
            "noHiredProjects": 0,
            "description": ""
        },
        {
            "id": 5,
            "name": "Engineering Research & Development (ER&D)",
            "image": "services/ER&D.png",
            "noHiredProjects": 0,
            "description": ""
        },
        {
            "id": 6,
            "name": "Training",
            "image": "services/training.png",
            "noHiredProjects": 0,
            "description": ""
        }
    ],
    "iotSectors": [
        {
            "id": 1,
            "name": "Edificios Inteligentes",
            "image": "iotSectors/building.jpg",
            "noHiredProjects": 0,
            "description": ""
        },
        {
            "id": 2,
            "name": "Industría Inteligente",
            "image": "iotSectors/industry.jpg",
            "noHiredProjects": 0,
            "description": ""
        },
        {
            "id": 3,
            "name": "Ciudades Inteligentes",
            "image": "iotSectors/cities.jpg",
            "noHiredProjects": 0,
            "description": ""
        },
        {
            "id": 4,
            "name": "Agricultura Inteligente",
            "image": "iotSectors/farming.jpg",
            "noHiredProjects": 0,
            "description": ""
        },
        {
            "id": 5,
            "name": "Salud Inteligente",
            "image": "iotSectors/health.jpg",
            "noHiredProjects": 0,
            "description": ""
        },
        {
            "id": 6,
            "name": "Transporte Inteligente",
            "image": "iotSectors/transport.png",
            "noHiredProjects": 0,
            "description": ""
        }
    ],
    "aboutus": {
        "whyOurName": "Combinamos las palabras \"Emb\", \"Light\" y \"Eng\" para formar la palabra \"EmbLightEng\" que es homónima a \"enlighten\" y que significa iluminar, enseñar, orientar, lo cual es el principal propósito de la consultoría. A su vez, las palabras \"Emb\", \"Light\" y \"Eng\" hacen referencia a los pilares de nuestra compañía: los sistemas embebidos (Emb), Los principios de innovación, conectividad y sencillez (Light), y el más obvio, Ingeniería (Eng).",
        "mission": "Apoyar a nuestros clientes en la toma de decisiones y en el desarrollo de soluciones tecnológicas, con el fin de contribuir en la construcción de un mundo más inteligente, justo y feliz.",
        "vision": "Ser reconocidos como uno de los proveedores de soluciones tecnológicas a la medida más confiables y transparentes.",
        "values":{
            "description": "Queremos contribuir a la construcción de un mundo más inteligente, justo y feliz. Para lograrlo, consideramos los siguientes principios: Sencillez, Innovación y Empatía",
            "list": ["Trustworthiness", "Respect", "Empathy", "curiosity", "Innovation", "Personal Development", "Goal-Oriented"]
        },
        "leaders": [
            {
                "id": 1,
                "name": "Duverney Corrales Mendoza",
                "profession": "M.Eng in Electronics",
                "roll": "Founder, CEO & Embedeed Engineer",
                "image": "team-duverneycm.png",
                "phone": "+573102720230",
                "email": "duverneycm@emblighteng.com",
                "linkedin": "https://www.linkedin.com/in/duverney-corrales-mendoza-62b09854/",
                "instagram": "https://www.instagram.com/duverneycm/",
                "github": "https://github.com/DuverneyCM",
                "description": "Ing. Electrónico con maestría, especializado en sistemas embebidos de alto rendimiento."
            },
            {
                "id": 2,
                "name": "Augusto Gomez Vinasco",
                "profession": "Systems Engeenering",
                "roll": "Founder & DevOps Engineer",
                "image": "team-augustogv.jpeg",
                "phone": "+573042445005",
                "email": "augustogv@emblighteng.com",
                "linkedin": "https://www.linkedin.com/in/augusto-gómez-96b54197/",
                "instagram": "https://www.instagram.com/gomezpirry/",
                "github": "https://github.com/gomezpirry/",
                "description": "Ing. en sistemas, especializado en video streaming y cloud computing."
            }
        ]
    }
}