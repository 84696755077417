import React, { Component } from 'react';
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import Home from './HomeComponent';
import About from './AboutComponent';
import Blog from './BlogComponent';
import Contact from './ContactComponent';
import { DB_ES } from '../shared/db_es';

class App extends Component {
render() {
	const DB = DB_ES;

	return (
		<div className="App">
			<Container>
				<Header />
				<Routes>
					<Route exact path='/' element={< Home 
						consultingSteps = {DB.consultingSteps}
						services = {DB.services}
						iotSectors = {DB.iotSectors}/>}></Route>
					<Route exact path='/aboutus' element={< About 
						aboutus = {DB.aboutus}/>}></Route>
					<Route exact path='/blog' element={< Blog />}></Route>
					<Route exact path='/contactus' element={< Contact />}></Route>
				</Routes>
			</Container>
			<Footer />
		</div>
    );
}
}

export default App;
