import React, { Component } from 'react';
import { Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, NavItem } from 'reactstrap';
// import { Jumbotron, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Input, Label, 
//   UncontrolledDropdown,DropdownToggle,DropdownMenu,DropdownItem,NavbarText } from 'reactstrap';
import { NavLink } from 'react-router-dom';

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggleNav = this.toggleNav.bind(this);
    // this.toggleModal = this.toggleModal.bind(this);
    // this.handleLogin = this.handleLogin.bind(this);

    this.state = {
      isNavOpen: false,
      isModalOpen: false
    };
  }

  toggleNav() {
    this.setState({
      isNavOpen: !this.state.isNavOpen
    });
  }

  // toggleModal() {
  //   this.setState({
  //     isModalOpen: !this.state.isModalOpen
  //   });
  // }

  // handleLogin(event) {
  //   this.toggleModal();
  //   alert("Username: " + this.username.value + " Password: " + this.password.value
  //       + " Remember: " + this.remember.checked);
  //   event.preventDefault();
  // }

  render() {
    return(
      <div>
        <Navbar light expand="sm">
          <NavbarBrand className="ml-auto" href="/">
            <div className="">
              <img src='assets/images/ELE-logo-pcb.svg' height="60" width="190" alt='EmbLightEng S.A.S' />
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNav} />
          <Collapse isOpen={this.state.isNavOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink className="nav-link" to='/'><span className="fa fa-home fa-lg"></span> Inicio</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link" to='/aboutus'><span className="fa fa-address-card fa-lg"></span> Quienes somos?</NavLink>                  
              </NavItem>
              <NavItem>
                <NavLink className="nav-link" to='/blog'><span className="fa fa-list fa-lg"></span> Blog</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="btn btn-outline-dark" role="button" to='/contactus'><span className="fa fa-comments fa-lg"></span> Contáctenos</NavLink>
              </NavItem>
            </Nav> 
          </Collapse>
        </Navbar>
        <div className="row justify-content-center">             
          <div className="col-auto text-center">
            <br/><br/>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;