// import React from 'react';

// function About () {
// 	return <div>
// 		<h2>GeeksforGeeks is a computer science portal for geeks!</h2>

// 		Read more about us at :
// 		<a href="https://www.geeksforgeeks.org/about/">
// 			https://www.geeksforgeeks.org/about/
// 		</a>
// 	</div>
// }
// export default About;

import React from 'react';
import { Breadcrumb, BreadcrumbItem, Media, Toast, ToastHeader, ToastBody,
    Card, CardBody, CardHeader, CardImg, CardTitle, CardSubtitle, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';
import { baseUrl } from '../shared/baseUrl';
import { FadeTransform, Fade, Stagger } from 'react-animation-components';

function RenderLeader({item}) {
    const imgStyle = {
        maxHeight: 128,
        maxWidth: 128,
        margin: 0
    }
    return(
        <div>
            <div key={item.id} className="col-auto mt-3">
                <Media tag="li">
                    <Media left middle>
                        <Media object src={item.image} style={imgStyle} alt={item.name} />
                    </Media>
                    <Media body className="ml-5 ">
                        <Media heading>{item.name}</Media>
                        <p>{item.roll}</p>
                        <p>{item.description}</p>
                    </Media>
                </Media>
            </div>
        </div>
    );
  }

function RenderLeader2({item}) {
    const imgStyle = {
        maxHeight: 128,
        maxWidth: 128,
        margin: 0
    }
    return(
        <div key={item.id}>
            <Card>
                <CardBody>
                    <CardTitle tag="h5">
                        {item.name}
                    </CardTitle>
                    <CardSubtitle className="mb-2 text-muted" tag="h6">
                        {item.roll}
                    </CardSubtitle>
                    <CardImg width="100%" src={"/assets/images/" + item.image} alt={item.name} />
                    {/* <img width="100%" src={"/assets/images/" + item.image} alt={item.name} /> */}
                    {/* <Media object src={item.image} width="100%" alt={item.name} /> */}
                    <CardText className="text-center">
                        {item.description}
                    </CardText>
                </CardBody>
            </Card>
        </div>
    );
  }

function About(props) {
    const leaders = props.aboutus.leaders.map((leader) => {
        return (
            <div  className="col-12 col-md-3 p-3 my-2" key={leader.id}>
                {/* <Stagger in>
                    <Fade in>                            
                        
                    </Fade>
                </Stagger> */}
                <RenderLeader2 item={leader} />
            </div>
        );
    });

    return(
        <div className="container">
            <div className="col">
                <h2 style={{textAlign: "center"}}>
                    {/* <text>Why </text> */}
                    <text>¿Porqué </text>
                    <text style={{ color: '#49BBC1' }}>Emb</text>
                    <text style={{ color: '#FFDD00' }}>Light</text>
                    <text style={{ color: '#BE1621' }}>Eng</text>
                    ?
                </h2>
                <div className="row" align="justify">
                    <br/>
                    <p>{props.aboutus.whyOurName}</p>
                </div>
            </div>

            <div className="col">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-4 p-3 my-2" style={{ background: '#49BBC1' }}>
                        <Toast style={{ height: "100%", width: "100%" }}>
                            <ToastHeader bg="danger">
                                {/* Our Mission */}
                                Nuestra Misión
                            </ToastHeader>
                            <ToastBody className="text-justify">
                                {props.aboutus.mission}
                            </ToastBody>
                        </Toast>
                    </div>
                    <div className="col-12 col-md-4 p-3 my-2" style={{ background: '#FFDD00' }}>
                        <Toast style={{ height: "100%", width: "100%" }}>
                            <ToastHeader bg="danger">
                                {/* Our Vision */}
                                Nuestra Visión
                            </ToastHeader>
                            <ToastBody className="text-justify">
                                {props.aboutus.vision}
                            </ToastBody>
                        </Toast>
                    </div>
                    <div className="col-12 col-md-4 p-3 my-2" style={{ background: '#BE1621' }}>
                        <Toast style={{ height: "100%", width: "100%" }}>
                            <ToastHeader bg="danger">
                                {/* Our Values */}
                                Nuestros Valores
                            </ToastHeader>
                            <ToastBody className="text-justify">
                                {props.aboutus.values.description}
                            </ToastBody>
                        </Toast>
                    </div>
                </div>
            </div>

            <br/>
            <div className="row justify-content-center text-center">
                <h2>Nuestros Lideres</h2>
                {leaders}
            </div>
            


            
            

        </div>
    );
}

export default About;    
