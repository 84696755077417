// import React from 'react';
  
// function Home (){
//     return <h1>Welcome to the world of Geeks!</h1>
// }
  
// export default Home;


import React from 'react';
import { Card, CardImg, CardImgOverlay, CardText, CardBody, CardTitle, CardSubtitle, Jumbotron} from 'reactstrap';
//import { Loading } from './LoadingComponent';
//import { baseUrl } from '../shared/baseUrl';
//import { FadeTransform } from 'react-animation-components';

function RenderMenuItem ({step}) {
  return (
      <Card>
          <CardImg width="100%" src={"/assets/images/" + step.image} alt={step.name} />
          <CardTitle><p>{step.name}</p></CardTitle>
      </Card>
  );
}

function RenderMenuOverlayItem ({step}) {
  return (
      <Card>
          <CardImg width="100%" src={"/assets/images/" + step.image} alt={step.name}  />
          <CardImgOverlay className="itemFormat">
              <CardTitle><h4 >{step.name}</h4></CardTitle>
          </CardImgOverlay>
      </Card>
  );
}

function Home(props) {
  const consultigSteps = props.consultingSteps.map((item) => {
    return (
      <div className="col-6 col-md-3 itemRenderConsulting" key={item.id}>
        <RenderMenuItem step={item} />
      </div>
    );
  });
  const serviceList = props.services.map((item) => {
    return (
      <div className="col-6 col-md-2 " key={item.id}>
        <RenderMenuItem step={item} />
      </div>
    );
  });
  const iotSectorsList = props.iotSectors.map((item) => {
    return (
      <div className="col-6 col-md-2 itemIotSectors" key={item.id}>
        <RenderMenuOverlayItem step={item} />
      </div>
    );
  });
  return(
    <div className="container">
        <img src="/assets/images/ELE-jumbotron-es.png" className="img-fluid" />  
        <br/><br/>
      
      <div className="container ">
        <div className="col-12 text-center">
          {/* <h3>We wanna help to create a smarter and sustainable IoT world</h3> */}
          <h3>Queremos ayudar a crear un mundo más inteligente y sostenible</h3>
        </div>
        <div className="col-12" align="justify">
            <p>Somos una empresa de consultoría en Ingeniería orientada a tecnologías IoT,
                en sectores como la agricultura (Smart Farming), industria (Smart Industry), 
                domótica (smart building) y urbanismo (smart cities). Nuestro principal objetivo
                es ayudarte a elegir bien. Nos encanta ayudar a solucionar los problemas de fondo. 
                Sabemos preguntar y llegar a la causa raiz de los problemas; escuchar y ser empáticos 
                con las necesidades de nuestros clientes y su entorno. Let us enlighten you :)
            </p>
        </div>
        <div className="row">
            {iotSectorsList}
        </div>
        <br/>
      </div>  

      {/* <div className="container ConsultantSection">
          <div className="col text-center">
            <h2>End-to-end solutions</h2>
            <p>We develop custom projects according to your needs and requirements </p>
          </div>
          <div className="row">
              {consultigSteps}
          </div>
        <br/>
      </div> */}

      <div className="container ServiceSection">
        <div className="col-12 text-center">
          {/* <h3>Our services</h3> */}
          <h3>Nuestros Servicios</h3>
          {/* <p>We develop end-to-end solutions according to your needs and requirements, providing you a wide variety of ER&D services like 
            embedded development, web/app development, analytics, AI and cloud automation.  </p> */}
          <p></p>
          {/* <p>Our services are supported on a wide range of technological</p> */}
        </div>
        <div className="row">
            {/* {serviceList} */}
            <div className="col-12 col-md-3 text-center">
                <p>Brindamos servicios de orientación, planificación, diseño y ejecución
                     de proyectos de Ingeniería y gestión de la información.</p>
            </div>
            <div className="col-12 col-md-3 text-center">
                <p>Proveemos una amplia variedad de servicios I+D+i como desarrollo de sistemas embebidos, 
                    desarrollo web y apps, inteligencia artificial, analitica y automatización de servicios en la nube.</p>
            </div>
            
            <div className="col-12 col-md-3 text-center">
                <p>Integramos tecnologías comerciales para desarrollar prototipos y soluciones completas (end-to-end)
                     de bajo costo, de acuerdo a tus necesidades y requerimientos.</p>
            </div>
            <div className="col-12 col-md-3 text-center">
                <p>Diseñamos soluciones a la medida, tanto a nivel de hardware, software, conectividad y diseño industrial</p>
            </div>
            <p></p>

        </div>
        
      </div>


    </div>
  );
}

export default Home; 