// import React from 'react';

// function Contact (){
// return <address>
// 			You can find us here:<br />
// 			GeeksforGeeks<br />
// 			5th & 6th Floor, Royal Kapsons, A- 118, <br />
// 			Sector- 136, Noida, Uttar Pradesh (201305)
// 		</address>
// }

// export default Contact;

import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem,
            Button, Label, Col, Row } from 'reactstrap';
import { Control, LocalForm, Errors, actions } from 'react-redux-form';
//import { Link } from 'react-router-dom';
//import { Loading } from './LoadingComponent';
//import { baseUrl } from '../shared/baseUrl';
//import { FadeTransform } from 'react-animation-components';


const required = (val) => val && val.length;
const isNumber = (val) => !(val) || !isNaN(Number(val));
const maxminLength = (min,max) => (val) => val && (min <= val.length && val.length <= max);
const maxminLengthPhone = (min,max) => (val) => isNumber(val) && (min <= val.length && val.length <= max);
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => val && (val.length >= len);
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);


class Contact extends Component {
  constructor(props) {
      super(props);

      this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    //console.log('Current State is: ' + JSON.stringify(values));
    //alert('Current State is: ' + JSON.stringify(values));
      //update to show the saved data
    //   this.props.postFeedback(
    //       values.firstname, 
    //       values.lastname, 
    //       values.telnum, 
    //       values.email, 
    //       values.agree, 
    //       values.contactType,
    //       values.message
    //   );
      
      //var addFeedback
      //fetch(baseUrl + 'feedback')
      //.then(  response => console.log('Current State is: ' + JSON.stringify(response))  )
      //.then(  response => alert('Current State is: ' + JSON.stringify(response))  );
      const addFeedback = () => this.props.addFeedback[this.props.addFeedback.length - 1];
    //   console.log('Current State is: ' + JSON.stringify(addFeedback));
    //   alert('Current State is: ' + JSON.stringify(addFeedback));
    //   this.props.resetFeedbackForm();
      //event.preventDefault();

    const endpoint =
        "https://tns44f7ya9.execute-api.us-east-1.amazonaws.com/default/sendContactEmail";
    const body = JSON.stringify({
        senderName: values.firstname + " " + values.lastname,
        senderEmail: values.email,
        senderTel: values.telnum,
        message: values.message
    });
    const requestOptions = {
        method: "POST",
        body
    };

    fetch(endpoint, requestOptions)
    .then((response) => {
      if (!response.ok) {
          var error = new Error('Error in fetch ' + response.status + ': ' + response.statusText);
          error.response = response;
          throw error;
      }
      return response.json();
    },
    error => {
        throw error;
    })
    // .then((response) => {
    //     if (!response.ok) {
    //         alert('Email sent successfully!');
    //     }
    //     else {
    //         alert('Email sent successfully!');
    //     }
    // })
    .then((response) => {
      //document.getElementById("result-text").innerText = "Email sent successfully!";
      alert('Email sent successfully!');
    })
    .catch((error) => {
      //document.getElementById("result-text").innerText = "An unkown error occured.";
      console.log('contact email', error.message);
      alert('Your contact email cannot been sent\nError: '+error.message);
    });

  }

  validate(firstname, lastname, telnum, email) {
      const errors = {
          firstname: '',
          lastname: '',
          telnum: '',
          email: ''
      };

      if (this.state.touched.firstname && firstname.length < 3)
          errors.firstname = 'First Name should be >= 3 characters';
      else if (this.state.touched.firstname && firstname.length > 10)
          errors.firstname = 'First Name should be <= 10 characters';

      if (this.state.touched.lastname && lastname.length < 3)
          errors.lastname = 'Last Name should be >= 3 characters';
      else if (this.state.touched.lastname && lastname.length > 10)
          errors.lastname = 'Last Name should be <= 10 characters';

      const reg = /^\d+$/;
      if (this.state.touched.telnum && !reg.test(telnum))
          errors.telnum = 'Tel. Number should contain only numbers';

      if(this.state.touched.email && email.split('').filter(x => x === '@').length !== 1)
          errors.email = 'Email should contain a @';

      return errors;
  }


  render() {
      return(
          <div className="container">
              <div className="row ">
                  <div className="col-12 align-items-center">
                      {/* <h3 style={{textAlign: "center"}}>Contact Us</h3> */}
                      <h3 style={{textAlign: "center"}}>Contáctenos</h3>
                      <br/>
                  </div>
                  <div className="col-12 col-md-8 offset-md-2">
                      <LocalForm model="feedback" onSubmit={(values) => this.handleSubmit(values)}>
                          <Row className="form-group">
                              {/* <Label htmlFor="firstname" md={2}>First Name</Label> */}
                              <Label htmlFor="firstname" md={2}>Nombres</Label>
                              <Col md={10}>
                                  <Control.text model=".firstname" id="firstname" name="firstname"
                                      placeholder=" Name"
                                      className="form-control"
                                      validators={{ maxminLength: maxminLength(2,32) }}
                                       />
                                  <Errors
                                      className="text-danger"
                                      model=".firstname"
                                      show="touched"  
                                      messages={{
                                        //   maxminLength: 'Firstname must be from 2 to 32 characters'
                                          maxminLength: 'El nombre debe tener entre 2 y 32 caracteres'
                                          // minLength: 'Must be greater than 2 characters',
                                          // maxLength: 'Must be 32 characters or less'
                                      }}
                                       />
                              </Col>
                          </Row>
                          <Row className="form-group">
                              {/* <Label htmlFor="lastname" md={2}>Last Name</Label> */}
                              <Label htmlFor="lastname" md={2}>Apellidos</Label>
                              <Col md={10}>
                                  <Control.text model=".lastname" id="lastname" name="lastname"
                                      placeholder="Last Name"
                                      className="form-control"
                                      validators={{ maxminLength: maxminLength(2,32) }}
                                       />
                                  <Errors
                                      className="text-danger"
                                      model=".lastname"
                                      show="touched"
                                      messages={{
                                        //   maxminLength: 'Lastname must be from 2 to 32 characters'
                                          maxminLength: 'El apellido debe tener entre 2 y 32 caracteres'
                                      }}
                                       />
                              </Col>
                          </Row>
                          <Row className="form-group">
                              {/* <Label htmlFor="telnum" md={2}>Contact Tel.</Label> */}
                              <Label htmlFor="telnum" md={2}>Tel. Contacto</Label>
                              <Col md={10}>
                                  <Control.text model=".telnum" id="telnum" name="telnum"
                                      placeholder="Phone Number"
                                      className="form-control"
                                      validators={{ isNumber }}
                                       />
                                  <Errors
                                      className="text-danger"
                                      model=".telnum"
                                      show="touched"
                                      messages={{
                                        //   isNumber: 'Must be a number'
                                          isNumber: 'Debe ser un número'
                                      }}
                                       />
                              </Col>
                          </Row>
                          <Row className="form-group">
                              <Label htmlFor="email" md={2}>Email</Label>
                              <Col md={10}>
                                  <Control.text model=".email" id="email" name="email"
                                      placeholder="Email"
                                      className="form-control"
                                      validators={{
                                          validEmail
                                      }}
                                       />
                                  <Errors
                                      className="text-danger"
                                      model=".email"
                                      show="touched"
                                      messages={{
                                        //   validEmail: 'Invalid Email Address'
                                          validEmail: 'Dirección Email Inválida'
                                      }}
                                       />
                              </Col>
                          </Row>
                          <Row className="form-group">
                              {/* <Label htmlFor="message" md={2}>Message</Label> */}
                              <Label htmlFor="message" md={2}>Mensaje</Label>
                              <Col md={10}>
                                  <Control.textarea model=".message" id="message" name="message"
                                      placeholder="Tell us your needs :)"
                                      rows="6"
                                      className="form-control" />
                              </Col>
                          </Row>
                          <Row className="form-group">
                              <Col md={{size:10, offset: 2}}>
                                  <Button type="submit" color="primary">
                                  {/* Send */}
                                  Enviar
                                  </Button>
                              </Col>
                          </Row>
                      </LocalForm>
                  </div>
              </div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>

          </div>
      );
  }
}

export default Contact;